export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["makes online food, grocery, parcel delivery and pharmacy shopping fast and easy. Get groceries delivered and order your favourite foods from the best vendors."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything you need, delivered now"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order food online, get groceries delivered, and shop online with the best grocery store in your area."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover local vendors that deliver to your doorstep"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the easy business train"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the easy business train and discover a simpler path to success. Get on board now and experience hassle-free entrepreneurship"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a Seller"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Register as a seller and open a shop to start your business today."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple payment options"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy order management"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offering multiple payment options "])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizing from start to finish."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a rider with us and earn more"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our team as a rider and unlock your earning potential. Enjoy the freedom of flexible hours and competitive pay while delivering happiness to customers."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a rider"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make your online shop easier with our mobile app"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline your online shop with ease using our mobile app. Manage your business on-the-go and stay connected with your customers anytime, anywhere"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions"])},
    "refund-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Policy"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do Business with us"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need Riders or Drivers?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to Sell on"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Account"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a store"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all rights reserved"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies to improve your experience on our website. By browsing this website, you agree to our use of cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Selling"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for products"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Results for"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found for"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nearby Vendors"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For You"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Vendors"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the best vendors in your area"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have all your favourite vendors here"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Arrivals"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Arrival"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Sales"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flash Sale"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's Picks"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Vendors"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose File"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Services"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Rated"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Stock"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Stock"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Seller"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended for you"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products from same vendor"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select options to add them to the product"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cart"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Product Price"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty. Start shopping now."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No extras available"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This subtotal doesn't include the delivery or pickup cost"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Item"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Price"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Amount"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have an account?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a discount code"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Discount"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping with SSL Encryption"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for a smoother checkout"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password must include at least 8 characters, 1 uppercase, 1 lowercase letter and 1 number"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email or Password is incorrect"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back to ASKNC!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Create Account you agree to our Privacy Policy and Terms & Conditions"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here order your favorite Grocery from different categories"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup Order"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate whether you will pickup order from the vendor"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select delivery address"])},
  "selectTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver tip"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Methods"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order Request"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order will be ready by"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Taxi"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Type"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order for a parcel pick up today"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who to pay?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sender"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send parcels or packages to friends and families."])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Recipients & Parameters Information"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Recipients"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Parameters"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your package"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Now"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Orders"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Packages"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your local on demand courier service"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Types"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Package Type"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courier Vendors"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Courier Vendor"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Info"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Pickup and Destination here"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where to ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a pickup location"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a dropoff location"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add stops"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a package type"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a courier"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipients"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient Info"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By confirming I accept this order doesn’t contain illegal/resticted items, if illegal/restricted items are found by "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner, they may report it to the law enforcement authorities. Terms and Conditions apply."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Parameters"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in all required package parameters here"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Details"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dropoffs"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Charges"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Type Charges"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient name is required"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient phone number is required"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How would you like to receive your order?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Order"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to checkout"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" We accept the following payment options"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Fee"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver Tip"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Order"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account settings"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't placed an order with us yet. When you do they will show up here."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Change"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Favourites"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Password"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Profile"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no Address"])}
  }
}